import React from "react";
import raimon from "../images/raimon100.webp"; // Importing personal image

function About() {
  return (
    <div>
      <main>
        <div className="header">
          <div className="spacing2"></div>
          <img
            className="common-properties common-properties-left2"
            src={raimon}
            alt="A selfie"
          ></img>
          <article>
            <div>
              <h1>Hello, I am Raimon</h1>
              <p>
During my digital journey, I combine technology with creativity, creating unique designs that push the boundaries of possibilities. This positive mindset helps me realize my true potential, unhindered by the expectations of others. My challenges have not hindered my personal and professional growth. I strongly believe that everyone, despite challenges, can achieve their dreams with determination and support. I greatly appreciate your understanding and interest in my story. I hope it inspires you to overcome obstacles and discover your full potential on your own journey.
              </p>
            </div>
          </article>
        </div>
      </main>
      <div className="spacing"></div>

      <div className="spacing"></div>
    </div>
  );
}
// Social media links with FontAwesome icons
// Copyright notice with dynamic year
// Button to scroll to the top of the page

export default About;
