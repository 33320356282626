import React from "react";
import idea from "../images/idea.webp";
import post_box from "../images/post_box.webp";

function Contact() {
  return (
    <div>
      <main>
        <div className="header">
          <img
            className="common-properties common-properties-left"
            src={post_box}
            alt="Illustration of a mailbox."
          />
          <br />
          <br />
          <article>
            <div>
              <h1>Contact</h1>
              <p>
                If you have any questions about the services I offer, please
                send me a message. I will get back to you as soon as possible.
              </p>
              <div className="spacing3"></div>
              <a className="Social" href="mailto:info@raimonvibe.com">
                Contact
              </a>
            </div>
          </article>
        </div>

        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={idea}
            alt="Illustration of a light bulb being screwed into a lamp shade"
          />
          <article>
            <div>
              <h1>If you have ideas.</h1>
              <p>
                Collecting feedback from clients is a way to show that I value
                their opinions. By asking my clients for feedback, I communicate
                that their opinions are important to me. I involve them in
                shaping my business, so they feel more connected to my company.
                By listening to their voices, I can build a sustainable
                connection with them.
              </p>
              <p>"We would love to hear from you."</p>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}
// Social media links with FontAwesome icons
// Copyright notice with dynamic year
// Button to scroll to the top of the page

export default Contact;
