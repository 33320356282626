import React from "react";
import collabs from "../images/gosolo.webp";
import techbullion from "../images/techbullion.webp";

function Clients() {
  return (
    <div>
      <main>
         <div className="middle-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.collabs.io/mag/raimonvibe/"
          >
            <img
              className="common-properties common-properties-left"
              src={collabs}
              alt="My company in the online magazine Collabs"
            />
          </a>
          <article>
            <div>
              <h1>Interviewed by Collabs</h1>
              <p>
                I was interviewed for the online magazine Collabs. I was very
                surprised when I was asked to write a story about my company.
                Read my story by clicking on the image.
              </p>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="middle-container">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://techbullion.com/is-software-development-a-dead-end-job-after-age-35/"
          >
            <img
              className="common-properties common-properties-right"
              src={techbullion}
              alt="My company in the online magazine TechBullion"
            />
          </a>
          <article>
            <div>
              <h1>Fintech Magazine TechBullion</h1>
              <p>
                I am honored to share that I was recently featured in Fintech
                Magazine with my insights, published by TechBullion. This is an
                important milestone in my journey and I am grateful for the
                opportunity to share my knowledge. Read my story by clicking on
                the image.
              </p>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}

export default Clients;
