import React from "react";
import blog from "../images/blog.webp";
import youtube from "../images/youtube.webp";
import developer from "../images/developer.webp";

function Services() {
  return (
    <div>
      <main>
        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={developer}
            alt="Illustratie van een developer."
          ></img>
          <article>
            <div>
              <h1>Software Engineering Solutions</h1>
              <p>
                As an experienced Software Engineer, I create contemporary,
                user-friendly websites using established techniques. I also
                provide code documentation services, ensuring clarity and
                facilitating comprehension for aspiring developers. With an
                active presence on Upwork & Fiverr, I am dedicated to delivering
                high-quality work with precision and attention to detail.
              </p>
              <div className="spacing"></div>
              <span>
                <p>Check out my Freelance profile at:</p>
                <strong>
                  <a
                    className="Social"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.upwork.com/freelancers/~01db073e93aefd8fac"
                  >
                    Upwork
                  </a>
                </strong>
                <br></br>
                <div className="spacing"></div>
                <strong>
                  <a
                    className="Social"
                    target="_blank"
                    rel="noreferrer"
                    href="https://nl.fiverr.com/raimonvibe"
                  >
                    Fiverr
                  </a>
                </strong>
              </span>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
        <div className="middle-container2">
          <div>
            <img
              className="common-properties common-properties-left"
              src={blog}
              alt="Illustratie van een blogomgeving."
            ></img>
            <article>
              <div>
                <h1>Blogs about my Studies</h1>
                <p>
                  On Medium, I share in-depth articles about my experiences and
                  learning processes in online coding bootcamps, including project
                  examples and scripts. Additionally, I passionately write about
                  3D printing, highlighting my unique design process, steps for
                  publication, and my motivation for this innovative technology.
                  As a content creator on YouTube, I also share my experiences and
                  knowledge in an accessible manner. This is just a glimpse of the
                  diversity on my Medium blog, where I invite you to discover more
                  about my professional and creative journey in the exciting world
                  of technology and design.
                </p>
                <p>Check out my blog posts at:</p>
                <strong>
                  <a
                    className="Social left-align"
                    target="_blank"
                    rel="noreferrer"
                    href="https://medium.com/@raimonvibe"
                  >
                    Medium
                  </a>
                </strong>
                <div className="spacing"></div>
              </div>
            </article>
          </div>
        </div>

        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={youtube}
            alt="Illustratie of a video-creation environment."
          ></img>
          <article>
            <div>
              <h1>YouTube Creator</h1>
              <p>
                My YouTube channel not only offers instructional videos on creating 3D
                models but also explores the fascinating world of AI technology
                and the technical aspects of starting an online business. I
                share insights about the software that is crucial for my
                business, providing a valuable source of knowledge for anyone
                interested in technology and online entrepreneurship.
              </p>
              <div className="spacing"></div>
              <span>
                <p>Check out my YouTube channel at:</p>
                <strong>
                  <a
                    className="Social"
                    target="_blank"
                    rel="noreferrer"
                    href="https://www.youtube.com/channel/UCDGDNuYb2b2Ets9CYCNVbuA/videos"
                  >
                    YouTube
                  </a>
                </strong>
              </span>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}

export default Services;
