import React from "react";

function LegalNotice() {
  return (
    <div>
      <main>
        <div className="body2">
          <div className="spacing3"></div>
          <div className="spacing3"></div>

          <p className="c1">
            <span className="c0" id="top">
              raimonvibe
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Last updated: January, 2023</span>
          </p>
          <p className="c1">
            <span className="c0">
              Welcome to raimonvibe. By using our website, you agree to the
              terms and conditions set out in this legal notice.
            </span>
          </p>

          <p className="c1">
            <span className="c0">
              Please read the legal notice carefully before using our website.
              If you have any questions, please contact us.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Legal Notice</span>
          </p>
          <p className="c1">
            <span className="c0">
              The information on this website is intended for general
              information purposes only. While we endeavor to ensure that the
              information on this website is accurate and up-to-date, we make no
              representations or warranties of any kind, express or implied,
              about the completeness, accuracy, reliability, suitability, or
              availability with respect to the website or the information,
              products, services, or related graphics contained on the website
              for any purpose. Any reliance you place on such information is
              therefore strictly at your own risk.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Raimonvibe cannot be held liable for any loss or damage,
              including without limitation, direct or indirect, consequential or
              incidental damages, arising from or in connection with the use of
              this website.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              This website may contain links to third-party websites that are
              not under the control of Raimonvibe. We have no control over the
              nature, content, and availability of those sites, and the
              inclusion of any links does not necessarily imply an endorsement
              or recommendation of the views expressed within them.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              We strive to keep this website running smoothly. However,
              Raimonvibe takes no responsibility for, and will not be liable
              for, the website being temporarily unavailable due to technical
              issues beyond our control.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0">Contact us: </span>
          </p>
          <p className="c1 c2">
            <span className="c0">Email: info@raimonvibe.com</span>
          </p>
          <p className="c1">
            <span className="c0">Website: https://www.raimonvibe.com</span>
          </p>
          <p className="c1">
            <span className="c0">
              By using our website, you accept our terms of use and privacy
              policy. The information on this website is intended for general
              information purposes and is not intended as legal advice. We do
              not guarantee the accuracy, completeness, or reliability of any
              information on this website and will not be liable for any errors
              or omissions.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Copyright © 2024 raimonvibe. All rights reserved.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <div className="spacing3"></div>
          <div className="spacing3"></div>
        </div>

        <div className="spacing"></div>
      </main>
    </div>
  );
}
// Social media links with FontAwesome icons
// Copyright notice with dynamic year
// Button to scroll to the top of the page
export default LegalNotice;
