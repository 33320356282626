import React from "react";
import pre_programming from "../images/Pre-Programming.webp";
import html_css from "../images/HTML_CSS.webp";
import javascript1 from "../images/JavaScript1.webp";
import javascript2 from "../images/JavaScript2.webp";
import webdevelopment from "../images/WebDevelopment.webp";
import python from "../images/Python.webp";
import python_tableau from "../images/Python_Tableau.webp";
import flutter from "../images/Flutter.webp";
import java1 from "../images/Java1.webp";
import dsa from "../images/dsa.webp";
import assertive from "../images/assertive.webp";
import ai from "../images/ai.webp";
import criminology from "../images/criminology.webp";
import tensorflow from "../images/tensorflow.webp";
import git from "../images/git.webp";
import django from "../images/django-1.webp";
import cSharp from "../images/CSharp.webp";
import CSharpIntermediate from "../images/CSharpIntermediate.webp";
import csharpadvanced from "../images/csharpadvanced.webp";
import CSharpUnitTesting from "../images/CSharpUnitTesting.webp";



function Certificates() {
  return (
    <div>
      <main>
        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={pre_programming}
            alt="My Pre-Programming certificate."
          />
          <article>
            <div>
              <h1 className="exlusive-text">Pre-Programming</h1>
              <p>
                In this course, I learned everything needed to start with
                programming, including the history of technology-related topics.
              </p>
              <a
                href="https://www.udemy.com/course/pre-programming-everything-you-need-to-know-before-you-code/"
                target="_blank"
                rel="noreferrer"
                className="Social align"
              >
                Pre-Programming
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={html_css}
            alt="My HTML & CSS certificate"
          />
          <article>
            <div>
              <h1 className="exlusive-text">HTML5 & CSS3</h1>
              <p>
                In this course series, I learned the markup language HTML5 and
                the style language CSS3, which are the foundation for building a
                website.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/the-ultimate-html-css"
              >
                HTML5 & CSS3
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={javascript1}
            alt="My JavaScript 1 certificate"
          />
          <article>
            <div>
              <h1 className="exlusive-text">JavaScript 1</h1>
              <p>
                In JavaScript Part 1, I learned the basics of programming. This
                course teaches the basic principles that are common in every
                programming language.
              </p>
              <a
                href="https://codewithmosh.com/p/javascript-basics-for-beginners"
                target="_blank"
                rel="noreferrer"
                className="Social align"
              >
                JavaScript 1
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={javascript2}
            alt="My JavaScript 2 certificate"
          />
          <article>
            <div>
              <h1>JavaScript 2</h1>
              <p>
                In JavaScript Part 2, I learned more advanced programming
                concepts. The course content includes Object-Oriented
                Programming.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/object-oriented-programming-in-javascript"
              >
                JavaScript 2
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className=" common-properties certificate-left"
            src={webdevelopment}
            alt="My Web Development certificate"
          />
          <article>
            <div>
              <h1 className="exlusive-text">Web Development</h1>
              <p>
                In this complete Web Development bootcamp, I learned how to
                program a website from the ground up to more in-depth topics.
                Topics discussed include developing dynamic websites with a
                database and authentication features.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/the-complete-web-development-bootcamp/"
              >
                Web Development
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={python}
            alt="My Python certificate"
          />
          <article>
            <div>
              <h1>Python Mastery</h1>
              <p>
                This course provides a complete overview of the applications
                that can be created with the Python programming language.
                Fundamentals of programming, Web development, and Machine
                Learning are all covered in this course.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/python-programming-course-beginners"
              >
                Python Mastery
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={python_tableau}
            alt="My Python & Tableau certificate"
          />
          <article>
            <div>
              <h1>Python & Tableau</h1>
              <p>
                Data analysis and data visualization are topics covered in this
                course. Here, you learn how to clean, process, and create data.
                These data are then presented in a clear document using colorful
                tables and graphs.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/python-and-tableau-the-complete-data-analytics-bootcamp/"
              >
                Python & Tableau
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={flutter}
            alt="My Flutter certificate"
          />
          <article>
            <div>
              <h1>Flutter</h1>
              <p>
                Flutter is a framework from Google for mobile app development
                for both iOS and Android. In this course, I taught myself how to
                develop mobile apps using the Dart programming language.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/flutter-bootcamp-with-dart/"
              >
                Flutter
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={java1}
            alt="My Java 1 certificate"
          />
          <article>
            <div>
              <h1>Java 1</h1>
              <p>
                In Java 1, I learned the basics of programming in Java. It is
                strong, suitable for developing large applications, and runs in
                a virtual machine.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/ultimate-java-part-1"
              >
                Java 1
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={dsa}
            alt="My certificate in Data Structures & Algorithms"
          />
          <article>
            <div>
              <h1>DSA</h1>
              <p>
                Data structures are ways to organize and store data, while
                algorithms are sets of instructions used to solve problems or
                perform tasks. Understanding both is crucial for designing
                efficient software solutions.
              </p>
              <a
                className="Social left-align align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/js-algorithms-and-data-structures-masterclass/"
              >
                DSA
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={assertive}
            alt="My certificate in Assertive Communication"
          ></img>
          <article>
            <div>
              <h1>AC Masterclass</h1>
              <p>
                The Assertive Communication Skills Masterclass provides
                individuals with techniques to improve assertive communication,
                including conflict resolution and effective listening, aiming to
                improve communication styles, self-awareness, and non-verbal
                communication.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/assertive-communication-skills-masterclass/"
              >
                AC Masterclass
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>
        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={ai}
            alt="My certificate in Artificial Intelligence"
          />
          <article>
            <div>
              <h1>Artificial Intelligence</h1>
              <p>
                In the "Artificial Intelligence A-Z" course, I delve deep into
                the world of AI using Python, even without prior programming
                experience. With downloadable code templates and intuitive
                tutorials, I learn how to build self-improving AI systems.
                Furthermore, I focus on real-world applications and receive
                support from experienced Data Scientists.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/artificial-intelligence-az/"
              >
                Artificial Intelligence
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={git}
            alt="My certificate in Git Version Control"
          />
          <article>
            <div>
              <h1>Git</h1>
              <p>
                In my Git course, I have acquired in-depth knowledge and skills
                in version control, an essential aspect for any developer. I
                learned how to efficiently track code changes, collaborate with
                teams, and manage projects in an organized manner. Through
                hands-on exercises, I gained experience in setting up
                repositories, making commits, merging branches, and resolving
                conflicts. This course has not only taught me technical skills
                but also emphasized the importance of good collaboration and
                communication in software development projects. My ability to
                use version control as a tool for effective teamwork and project
                management has significantly improved.
              </p>
              <a
                className="Social"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/the-ultimate-git-course"
              >
                Git
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={criminology}
            alt="My certificate in Criminology"
          />
          <article>
            <div>
              <h1>Criminology</h1>
              <p>
                In my criminology course, I explored psychological theories
                about why people commit crimes, looking at factors such as
                social influences and personality traits. I also learned about
                the criminal justice system's approach to rehabilitation and
                punishment. This study has enabled me to analyze criminal
                behavior from a psychological perspective, taking into account
                underlying motives and societal impacts.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/crime-psychology/"
              >
                Criminology
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={tensorflow}
            alt="My certificate in TensorFlow"
          />
          <article>
            <div>
              <h1>TensorFlow</h1>
              <p>
                After obtaining the TensorFlow Developer Certificate, I have
                gained extensive knowledge of the fundamentals of machine
                learning and deep learning. I learned how to use TensorFlow and
                Keras to develop, train, and deploy complex machine learning
                models. This course has equipped me with the skills to build
                neural networks for image and text recognition, time series
                analysis, and more, using the latest techniques in artificial
                intelligence.
              </p>
              <a
                className="Social"
                target="_blank"
                rel="noreferrer"
                href="https://www.udemy.com/course/tensorflow-developer-certificate-machine-learning-zero-to-mastery/"
              >
                TensorFlow
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={django}
            alt="My certificate in Django Backend Development part 1"
          />
          <article>
            <div>
              <h1>Django part 1</h1>
              <p>
                In the initial part of the "Ultimate Django Series," I delve
                into the essentials of web development using Python and Django,
                learning to set up MySQL, handle databases with Django's
                migrations, and use Django models for database interactions. The
                course offers practical exercises and insights into using
                Django's ORM and Admin interface, equipping me with skills for
                troubleshooting and applying best practices in web development.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/the-ultimate-django-series"
              >
                Django part 1
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={cSharp}
            alt="My certificate in C# Basics"
          />
          <article>
            <div>
            <h1>C#</h1>
            <p>
              After completing the "C# Basics for Beginners" course at Code with Mosh, I have acquired comprehensive knowledge of programming with C# and the .NET Framework.
              I learned about primitive and reference types, operators, control flow statements, and working with data, text, and files. This course equipped me with the
              skills to debug and develop C# applications through hands-on exercises and practical examples. It provides a solid foundation for a career in web, mobile, or game development.
            </p>
            <a
              className="Social"
              target="_blank"
              rel="noreferrer"
              href="https://codewithmosh.com/p/csharp-basics-for-beginners"
            >
              C#
            </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={CSharpIntermediate}
            alt="My certificate in C# Intermediate"
          />
          <article>
            <div>
              <h1>C# Intermediate</h1>
              <p>
              C# is a programming language developed by Microsoft for the .NET platform. In this course, I learned object-oriented programming, including classes, inheritance, and polymorphism.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://members.codewithmosh.com/p/object-oriented-programming-in-csharp"
              >
                CSharpIntermediate
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-left"
            src={csharpadvanced}
            alt="My certificate in C# Basics"
          />
          <article>
            <div>
            <h1>C# Advanced</h1>
            <p>
The "C# Advanced" course by Mosh Hamedani delves into complex topics like generics, delegates, events, lambda expressions, extension methods, LINQ, async/await, and dynamic typing to enhance coding efficiency and flexibility. This course is perfect for those aiming to deepen their C# knowledge, prepare for coding interviews, or master advanced features for professional development.
            </p>
            <a
              className="Social"
              target="_blank"
              rel="noreferrer"
              href="https://codewithmosh.com/p/csharp-advanced"
            >
              C# Advanced
            </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

        <div className="spacing"></div>
        <div className="middle-container">
          <img
            className="common-properties certificate-right"
            src={CSharpUnitTesting}
            alt="My certificate in C# Unit Testing"
          />
          <article>
            <div>
              <h1>C# Unit Testing</h1>
              <p>
              Using the TestNinja in this course, I learned critical unit testing techniques in C#, including Test-Driven Development (TDD), dependency injection, and mocking frameworks. This training enhanced my ability to write clean, maintainable tests and refactor legacy code for better testability​.
              </p>
              <a
                className="Social align"
                target="_blank"
                rel="noreferrer"
                href="https://codewithmosh.com/p/unit-testing-for-csharp-developers"
              >
                C# Unit Testing
              </a>
              <div className="spacing3"></div>
            </div>
          </article>
        </div>

      </main>

      <div className="spacing"></div>
    </div>
  );
}
// Social media links with FontAwesome icons
// Copyright notice with dynamic year
// Button to scroll to the top of the page

export default Certificates;
