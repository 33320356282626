import React from 'react';
import './webshop.css';

const products = [
  {
      name: 'Coral and Arctic Mouse Pad',
      description: 'Immerse yourself in the serene beauty of the Coral and Arctic design with this high-quality mouse pad. Perfect for any workspace, it combines functionality with stunning visuals.',
      price: '€33.67',
      imageUrl: 'https://ih1.redbubble.net/image.5581773207.7023/ur,desk_mat_flatlay_prop,wide_portrait,750x1000.u2.jpg',
      link: 'https://www.redbubble.com/i/mouse-pad/Coral-and-Arctic-by-raimonvibe/162587023.GAP22'
  },

  {
      name: 'Floral Serenity Dress',
      description: 'Step out in style with the Floral Serenity dress. This beautiful dress features an elegant floral pattern, perfect for any occasion. Lightweight and comfortable, it’s designed to make you feel as good as you look.',
      price: '€48.90',
      imageUrl: 'https://ih1.redbubble.net/image.5581764700.6742/aldr,x1700,front-c,185,103,750,1000-bg,f8f8f8.u2.jpg',
      link: 'https://www.redbubble.com/i/dress/Floral-Serenity-by-raimonvibe/162586742.V4WQ8'
  },
  {
    name: 'Floral Serenity Clock',
    description: 'Bring serenity into your home with this beautiful clock.',
    price: '€31.52',
    imageUrl: 'https://ih1.redbubble.net/image.5581764635.6742/clkc,white,white,1000x1250-pad,750x1000,f8f8f8.u2.jpg',
    link: 'https://www.redbubble.com/i/clock/Floral-Serenity-by-raimonvibe/162586742.7PFC0'
  },
  {
      name: 'Striking Waves iPhone Wallet',
      description: 'Embrace modern elegance with the Striking Waves iPhone Wallet, featuring a dynamic pattern of flowing lines in black, yellow, and white. Durable and stylish, this wallet\'s high-quality material and secure elastic strap make it a must-have accessory for any fashion-forward individual.',
      price: '€30.22',
      imageUrl: 'https://ih1.redbubble.net/image.5605051240.3895/wallet,1000x,iphone_6s_wallet-pad,750x1000,f8f8f8.jpg',
      link: 'https://www.redbubble.com/i/iphone-case/Dynamic-Flow-by-raimonvibe/163313895.TALA6'
  },
  {
      name: 'Blossom Whispers Pet Blanket',
      description: 'Wrap your furry friend in the soft embrace of this charming pet blanket, perfect for adding warmth and style to your pet’s favorite spot.',
      price: '€16.87',
      imageUrl: 'https://ih1.redbubble.net/image.5619580803.3680/ur,pet_blanket_small_flatlay_prop,wide_portrait,750x1000.jpg',
      link: 'https://www.redbubble.com/i/pet-blanket/Blossom-Whispers-by-raimonvibe/163763680.T97H3'
  },
  {
      name: 'ChocoRose Elegance Duffle Bag',
      description: 'Travel in style with the ChocoRose Elegance duffle bag. This elegant duffle bag features a sophisticated design with rich chocolate and rose tones, perfect for weekend getaways or daily use. Durable and spacious, it combines functionality with fashion.',
      price: '€53.98',
      imageUrl: 'https://ih1.redbubble.net/image.5581716169.5047/ur,duffle_bag_large_front,wide_portrait,750x1000.u3.jpg',
      link: 'https://www.redbubble.com/i/duffle-bag/ChocoRose-Elegance-by-raimonvibe/162585047.TZ9LO'
  },
  {
    name: 'Vibrant Swirls Bathmat',
    description: 'Transform your bathroom with the Vibrant Swirls Bathmat, showcasing a stunning design of dynamic swirls in black, yellow, and white. Crafted from soft, high-quality materials, this bathmat not only adds a touch of modern elegance but also provides comfort and durability, making it an essential addition to any stylish home.',
    price: '€18.79',
    imageUrl: 'https://ih1.redbubble.net/image.5605091778.5075/ur,bathmat_context_small,wide_portrait,750x1000.1.jpg',
    link: 'https://www.redbubble.com/i/bath-mat/Lavender-Mist-by-raimonvibe/163315075.EVFTZ'
  },
  {
    name: 'Nature\'s Verdant Embrace Clock',
    description: 'A clock that brings the tranquility of nature into your home.',
    price: '€31.52',
    imageUrl: 'https://ih1.redbubble.net/image.5584176657.6827/clkc,white,white,1000x1250-pad,750x1000,f8f8f8.u2.jpg',
    link: 'https://www.redbubble.com/i/clock/Nature-s-Verdant-Embrace-by-raimonvibe/162586827.7PFC0'
  },
  {
      name: 'Floral Serenity Mug',
      description: 'Enjoy your favorite beverage with the serene beauty of the Floral Serenity design. This mug features a beautiful floral pattern, perfect for adding a touch of elegance to your drinkware collection.',
      price: '€16.60',
      imageUrl: 'https://ih1.redbubble.net/image.5581764622.6742/mug,tall,x1000,right-pad,750x1000,f8f8f8.u2.jpg',
      link: 'https://www.redbubble.com/i/mug/Floral-Serenity-by-raimonvibe/162586742.W3OIY'
  },

  {
      name: 'Coral and Arctic Pet Bandana',
      description: 'Adorn your pet with the vibrant and cool hues of the Coral and Arctic design. This stylish and comfortable pet bandana is perfect for adding a touch of personality to your furry friend.',
      price: '€11.53',
      imageUrl: 'https://ih1.redbubble.net/image.5581774697.7023/ur,pet_bandana_size_comparison,tall_portrait,750x1000.u2.jpg',
      link: 'https://www.redbubble.com/i/pet-bandana/Coral-and-Arctic-by-raimonvibe/162587023.4ZW7E'
  }

];

const Webshop = () => {
  return (
    <>
      <a href="https://www.redbubble.com/people/raimonvibe/shop" target="_blank" rel="noopener noreferrer" class="shop-link">
          <span class="arrow">&#x21DD;</span>
          <h5>Only Unique Items!</h5>
      </a>
      <div className="webshop">
        {products.map((product, index) => (
          <div key={index} className="product">
            <a href={product.link} target="_blank" rel="noopener noreferrer">
              <img
                src={product.imageUrl}
                alt={product.name}
                className="product-image"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "https://via.placeholder.com/750x1000";
                }}
              />
            </a>
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <p>{product.price}</p>
          </div>
        ))}
      </div>
    </>
  );
};




export default Webshop;
