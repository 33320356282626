import React, { useState } from 'react';

const Dropdown11 = ({ links }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="dropdown11">
      <h1 class="menu-button" onClick={toggleDropdown}>
        Menu
      </h1>
      {isOpen && (
        <ul className="dropdown11-links">
          {links.map((link, index) => (
            <li key={index}>
              {link.to ? (
                <a href={link.to}>{link.label}</a>
              ) : (
                <a href={link.href} target={link.target} rel="noopener noreferrer">{link.label}</a>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default Dropdown11;
