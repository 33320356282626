import React from "react";
import { Link } from "react-router-dom"; // Importeren van noodzakelijke componenten van react-router-dom
import cover from "../images/cover.webp"; // Importeren van de cover afbeelding
import Dropdown11 from './Dropdown11'; // Importeren van de Dropdown11 component

function NavbarHome() {
  // Array met objecten voor elke navigatielink
  const navLinks = [
    { to: "/", label: "Home" },
    { to: "/Services", label: "Services" },
    { to: "/Opportunities", label: "Opportunities" },
    { to: "/About", label: "About Me" },
    { to: "/Clients", label: "Clients" },
    { to: "/ThreeDLibrary", label: "ThreeDLibrary" },
    { to: "/Certificates", label: "Certificates" },
    { href: "https://raimonvibe.com/Resume", label: "My Resume", target: "_blank" },
    { to: "/Contact", label: "Contact" },
    { to: "/Store", label: "Webshop" }
  ];

  return (
    <div>
      <nav className="navbar">
        <Link to="/">
          <img
            className="logo"
            src={cover}
            alt="Logo of my company"
          />
        </Link>
        <Dropdown11 links={navLinks} /> {/* Geef alle navLinks door aan Dropdown11 */}
      </nav>
    </div>
  );
}

export default NavbarHome; // Exporteren van de NavbarHome component
