import React from "react";
import resume from "../images/resume.pdf";

function Resume() {
  return (
    <div>
      <a href={resume} target="_blank" rel="noopener noreferrer">
        <div id="pdf-container">
          <iframe
            src={resume}
            title="Resume PDF"  // Voegt een titel toe voor toegankelijkheid
          ></iframe>
        </div>
      </a>
      <div className="spacing"></div>
      <div className="spacing"></div>
    </div>
  );
}

export default Resume;
