import React from "react";

function PrivacyNotice() {
  return (
    <div>
      <main>
        <div className="body2">
          <div className="spacing3"></div>
          <div className="spacing3"></div>

          <p className="c1">
            <span className="c0" id="top">
              The company, located at Timpaan 1-B 1628 MT Hoorn, is responsible
              for the processing of personal data as shown in this privacy
              statement.
            </span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Contact details:</span>
          </p>
          <p className="c1">
            <span className="c0">info@raimonvibe.com</span>
          </p>
          <p className="c1 c2">
            <span className="c0"></span>
          </p>
          <p className="c1">
            <span className="c0">Personal data that we process</span>
          </p>
          <p className="c1">
            <span className="c0">
              Raimonvibe takes your privacy seriously and is transparent about
              the personal data we process. When you visit our website or use
              our services, we may process the following personal data about
              you:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Contact information such as your email address, if you voluntarily
              provide it to us when filling out a contact form or signing up for
              our newsletter.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Information about your browsing behavior on our website to improve
              our services and your user experience. This includes data
              collected through cookies, such as pages visited, click behavior,
              and the duration of your visit.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Purpose of and basis for processing personal data
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              We process your personal data for specific purposes and on valid
              legal grounds, including:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              To contact you and respond to your requests or questions, if you
              have given us permission to do so.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              For analyzing your use of our website with the aim to improve our
              website and services. We do this on the basis of our legitimate
              interest to continuously optimize our service delivery.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              To comply with legal obligations, such as maintaining records for
              tax purposes or responding to requests from competent authorities.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Sharing personal data with third parties</span>
          </p>
          <p className="c1">
            <span className="c0">
              Raimonvibe will not sell, share, or disclose your personal data
              to third parties without your permission, unless necessary for the
              performance of our agreement with you or to comply with a legal
              obligation. In cases where we cooperate with external service
              providers or partners, we ensure that appropriate agreements are
              in place to protect your privacy.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Security and retention of personal data</span>
          </p>
          <p className="c1">
            <span className="c0">
              We take the protection of your personal data seriously and
              implement appropriate technical and organizational measures to
              protect your data from unlawful processing, loss, misuse,
              unauthorized access, disclosure, alteration, or destruction. Your
              personal data will not be retained for longer than is strictly
              necessary to achieve the purposes for which your data is
              collected.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Special and/or sensitive personal data that we process
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Our website and/or service does not intend to collect data about
              website visitors who are younger than 16 years of age unless they
              have permission from their parents or guardian. However, we cannot
              check whether a visitor is older than 16. We therefore advise
              parents to be involved in their children's online activities in
              order to prevent data about children from being collected without
              parental consent. If you are convinced that we have collected
              personal information about a minor without this consent, please
              contact us at info@raimonvibe.com, and we will delete this
              information.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Purpose and basis for processing personal data
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - The company processes your personal data for the following
              purposes:
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - The company analyzes your behavior on the website to improve the
              website and tailor the range of products and services to your
              preferences.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              - The company tracks your surfing behavior on various websites
              which we adapt our products and services to your needs.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Automated decision-making</span>
          </p>
          <p className="c1">
            <span className="c0">
              The company makes business decisions based on automated processing
              on matters that can have (significant) consequences for people.
              These are decisions made by computer programs or systems, without
              the involvement of a person (for example, an employee of the
              company). The company uses the following computer programs or
              systems:
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a className="c5" href="https://heapanalytics.com/">
                https://heapanalytics.com/
              </a>
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a className="c5" href="https://cookie-script.com/">
                https://cookie-script.com/
              </a>
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              Cookies, or similar techniques, that we use
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The company uses functional, analytical, and tracking cookies. A
              cookie is a small text file that is saved in the browser of your
              computer, tablet, or smartphone when you first visit this website.
              The company uses cookies with a purely technical functionality.
              These ensure that the website works properly, and that, for
              example, your preferred settings are remembered. These cookies are
              also used to operate the website properly and optimize it.
              Additionally, we place cookies that track your surfing behavior so
              that we can offer customized content and advertisements.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              During your first visit to our website, we have already informed
              you about these cookies and asked for your permission to place
              them.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              You can opt out of cookies by setting your internet browser so
              that it no longer stores cookies. Additionally, you can also
              delete all information previously stored via your browser
              settings.
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a
                className="c5"
                href="https://veiliginternetten.nl/search/?q=cookies"
              >
                https://veiliginternetten.nl/cookies/
              </a>
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              On this website, cookies are also placed by third parties. These
              are, for example, advertisers and/or social media companies. Below
              is an overview:
            </span>
          </p>
          <p className="c1">
            <span className="c0">Cookie: Heap Analytics.</span>
          </p>
          <p className="c1">
            <span className="c0">Name: Heap Analytics.</span>
          </p>
          <p className="c1">
            <span className="c0">
              Function: Analytical cookie that measures website visits.
            </span>
          </p>
          <p className="c1">
            <span className="c0">Cookie: CookieScript.</span>
          </p>
          <p className="c1">
            <span className="c0">Name: CookieScript.</span>
          </p>
          <p className="c1">
            <span className="c0">
              Function: Provides a cookie pop-up window.
            </span>
          </p>
          <p className="c1">
            <span className="c0">View, adjust, or delete data</span>
          </p>
          <p className="c1">
            <span className="c0">
              You have the right to access, correct, or delete your personal
              data. You can do this yourself via the personal settings of your
              account. Additionally, you have the right to withdraw your consent
              for data processing or to object to the processing of your
              personal data by our company, and you have the right to data
              portability. This means that you can submit a request to us to
              send the personal data we have about you in a computer file to you
              or another organization named by you.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              If you wish to exercise your right to object and/or your right to
              data portability or if you have other questions/comments about
              data processing, please send a specific request to
              info@raimonvibe.com.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              To ensure that the request for access is made by you, we ask you
              to attach a copy of your identity document to the request. Make
              sure to black out your passport photo, MRZ (machine readable zone,
              the strip with numbers at the bottom of the passport), passport
              number, and Citizen Service Number (BSN) in this copy. This is to
              protect your privacy. The company will respond to your request as
              soon as possible, but within four weeks at the latest.
            </span>
          </p>
          <p className="c1">
            <span className="c0">
              The company also points out that you have the option to file a
              complaint with the national supervisory authority, the Personal
              Data Authority. This can be done via the following link:
            </span>
          </p>
          <p className="c1">
            <span className="c3">
              <a
                className="c5"
                href="https://autoriteitpersoonsgegevens.nl/nl/contact-met-de-autoriteit-persoonsgegevens/tip-ons"
              >
                https://autoriteitpersoonsgegevens.nl/
              </a>
            </span>
          </p>

          <p className="c1">How we protect personal data</p>
          <p className="c1">
            <span className="c0">
              Raimonvibe takes the protection of your data seriously and takes
              appropriate measures to prevent abuse, loss, unauthorized access,
              unwanted disclosure, and unauthorized modification. If you believe
              that your data is not properly secured or there are indications of
              abuse, please contact our customer service or at
              info@raimonvibe.com
            </span>
          </p>
          <div className="spacing3"></div>
          <div className="spacing3"></div>

          <div className="spacing"></div>
        </div>
      </main>
    </div>
  );
}
// Social media links with FontAwesome icons
// Copyright notice with dynamic year
// Button to scroll to the top of the page
export default PrivacyNotice;
