import React from "react";
import breathe from "../images/breathe.webp";
import welcome from "../images/welcome.webp";

function Home() {
  return (
    <div>
      <main>
        <div className="spacing"></div>
        <div className="video-container">
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/PhokNIST824"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video 1"
            ></iframe>
          </div>
        </div>
        <div className="spacing"></div>

        <div className="header">
          <img
            className="common-properties common-properties-left"
            src={welcome}
            alt="Illustration of technology"
          />
          <article>
            <div>
              <h1>About raimonvibe</h1>
              <p>
                Raimonvibe, led by Raimon, is a leading research company within the technology field. With more than two years of experience in programming, our company has explored and investigated a wide range of technologies to push the boundaries of digital innovation. Our research expertise includes advanced programming in Python, JavaScript, HTML and CSS, and Flutter Dart, with which we study both frontend and backend solutions for seamless and efficient integration.
              </p>
              <p>
                Our research projects utilize powerful frameworks and technologies such as React, Django, and Node.js, combined with database systems such as MongoDB and Mongoose. We are also proficient in Data Science and Data Visualization, using these skills to transform complex datasets into clear, insightful visual reports. With our thorough knowledge of programming AI with TensorFlow, we are able to research and develop concepts for customized AI solutions that support intelligent, data-driven decision-making.
              </p>
            </div>
          </article>
        </div>
        <div className="spacing"></div>
        <div className="video-container">
          <div className="video-responsive">
            <iframe
              src="https://www.youtube.com/embed/bSq3YELK5ww"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube video 2"
            ></iframe>
          </div>
        </div>
        <div className="spacing"></div>

        <div className="middle-container">
          <img
            className="common-properties common-properties-right"
            src={breathe}
            alt="Illustration of a living room"
          />
          <article>
            <div>
              <h1>Our Technological Impact</h1>
              <p>
                At raimonvibe, we are not only focused on developing technology, but also on understanding and innovating it. Our research initiatives extend to the use of modern server technologies, including VPS (Virtual Private Servers), to host robust, scalable applications that are accessible worldwide. Our skills with JQuery and deploying real-time web applications with Express enable us to build responsive and interactive websites that enhance the user experience.
              </p>
              <p>
                We take pride in our approach to continuous improvement and learning, where each project is seen as an opportunity to deepen our skills and explore new technologies. Our goal is to always be at the forefront of technological advancement, and to share our findings and developments with the broader community, thus contributing to the evolution of the technology industry.
              </p>
            </div>
          </article>
        </div>
        <div className="spacing"></div>
      </main>

      <div className="spacing"></div>
    </div>
  );
}

export default Home;
